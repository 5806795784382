<template>
  <div>
    <h4>Add Category</h4>
    <div class="row">
      <div class="col-md-7">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submit">
              <div class="form-group">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Category Title"
                  v-model="category.name"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <button class="btn btn-primary">
                  <i class="lni lni-save mr-2"></i>Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      category: {},
    };
  },

  methods: {
    submit() {
      this.$axios
        .post("/api/v1/admin/categories", this.category)
        .then((response) => {
          this.$router.push({
            name: "admin.categories.show",
            params: { id: response.data.id },
          });
        });
    },
  },
};
</script>
